<template>
    <section id="EditWager">
        <Wager v-if="isBeforeStart" edit :wager="wager" />
        <div v-else-if="wager && !success" class="settle">
            <div class="card">
                <div class="content">
                    <div class="name">
                        <div>
                            <h3>{{ wager.name }}</h3>
                            <p class="footnote">{{ wager.variant }}</p>
                        </div>
                        <Status :type="getStatus(wager)" />
                    </div>
                    <div class="bets">
                        <div class="row">
                            <h4>Bets Placed</h4>
                            <h4 class="count">{{ wager.bets ? Object.keys(wager.bets).length : 0 }}</h4>
                        </div>
                        <div class="row">
                            <h4>Winning Bets</h4>
                            <h4 class="count">{{ wager.status !== 'settled' ? (wager.bets ? 'Pending' : 'Not Available') : winningBets }}</h4>
                        </div>
                    </div>
                </div>
                <div class="winning-lines" v-if="wager.status === 'settled'">
                    <h4>Winning Line(s)</h4>
                    <div class="row" v-for="line in wager.winningLines" :key="line">
                        <h4>{{ wager.lines[line] }}</h4>
                        <h4 class="odds" :class="{ push: wager.winningLines.length > 1 }">{{ wager.winningLines.length > 1 ? 'PUSH' : wager.odds[line].toFixed(2) }}</h4>
                    </div>
                    <div class="row" v-if="(!wager.winningLines || wager.winningLines.length === 0) && wager.type !== 'overUnder'">
                        <h4>NONE OF THE ABOVE</h4>
                        <h4 class="odds">0.00</h4>
                    </div>
                    <div class="row" v-if="wager.type === 'overUnder'">
                        <h4>FINAL TOTAL</h4>
                        <h4 class="odds push">{{ wager.finalTotal }}</h4>
                    </div>
                </div>
            </div>
            <div v-if="!wager.bets" class="delete">
                <Button @onClick="isDeleting = true" theme="red">Delete Wager</Button>
                <p class="footnote">This wager did not receive any bets and can safely be deleted from the history books.</p>
                <Confirm v-if="isDeleting" okay="Yes" @okay="deleteWager()" @cancel="isDeleting = false">Are you sure you want to delete this wager?</Confirm>
            </div>
            <div v-if="wager.bets && wager.type !== 'overUnder'" class="lines">
                <div class="line" v-for="(line, index) in wager.lines" :key="line" @click="addToSettlement(index)" :class="{ active: selections.includes(index) }">
                    <h4>{{ line }}</h4>
                    <h4 class="odds">{{ wager.odds[index].toFixed(2) }}</h4>
                </div>
                <div class="buttons">
                    <Button arrow :disabled="selections.length !== 1" @onClick="settleWager">Settle Wager</Button>
                    <Button :disabled="selections.length <= 1" theme="grey" @onClick="settleWager">Push</Button>
                    <Button :disabled="selections.length > 0" theme="red" @onClick="settleWager">None of the Above</Button>
                    <p class="footnote">Although rare, this may occur if the end result was not an available line. It is the double-zero of sports betting.</p>
                </div>
            </div>
            <div v-if="wager.bets && wager.type === 'overUnder'" class="final-total">
                <div class="card card--form">
                    <form @submit="settleOverUnder">
                        <h3>Final Total</h3>
                        <input type="number" placeholder="0" v-model="finalTotal" min="0">
                        <Button arrow>Settle Wager</Button>
                    </form>
                </div>
            </div>
        </div>
        <div class="success card card--form" v-if="success">
            <h3>Success!</h3>
            <p>Your wager has been settled. Press the button below to manage your wagers.</p>
            <router-link :to="{ name: 'Wagers', params: { book_id: $route.params.book_id } }">
                <Button arrow>Manage</Button>
            </router-link>
        </div>
        <Options :options="options" :selected="1" />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';
    import Wager from '@/components/Wager.vue';
    import Status from '@/components/Status.vue';
    import Button from '@/components/Button.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { watchWager, deleteWager, settleWager, getBet } from '@/firebase/wagers.js';

    // Misc.
    import { MANAGE_OPTIONS } from '@/constants.js';
    import { getBetStatus } from '@/utils.js';

    export default {
        name: 'EditWager',
        components: {
            BookSelector,
            Options,
            Wager,
            Status,
            Button,
            Confirm,
        },
        data() {
            return {
                wager: null,
                bets: {},
                isDeleting: false,
                selections: [],
                success: false,
                finalTotal: '',
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
            isBeforeStart() {
                return this.wager && this.wager.startTime > Date.now();
            },
            winningBets() {

                let wins = 0;

                for (const ticket of Object.values(this.bets)) {
                    for (const bet of ticket.options.bets) {
                        if (bet.wager[0] === this.$route.params.wager_id) {
                            if (!ticket.void && getBetStatus(bet, { [this.$route.params.wager_id]: this.wager }) === 'win') wins++;
                        }
                    }
                }

                return wins;

            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {

            this.$store.dispatch('updateLoading', 1);

            watchWager(this.$route.params.wager_id).on('value', snapshot => {

                this.wager = snapshot.val();

                if (this.wager.winningLines) this.selections = this.wager.winningLines;
                if (this.wager.finalTotal !== undefined) this.finalTotal = this.wager.finalTotal;

                if (this.wager.bets) {
                    for (const bet of Object.keys(this.wager.bets)) {

                        this.$store.dispatch('updateLoading', 1);

                        getBet(bet).then(snapshot => {
                            this.bets = { ...this.bets, [snapshot.key]: snapshot.val() };
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                this.$store.dispatch('updateLoading', -1);

            });

        },
        methods: {
            getStatus(wager) {

                if (wager.status === 'open' && Date.now() > wager.startTime) {
                    return 'pending';
                }

                return wager.status;

            },
            deleteWager() {
                this.$store.dispatch('updateLoading', 1);
                deleteWager(this.$route.params.book_id, this.$route.params.wager_id).then(() => {
                    this.$store.dispatch('updateLoading', -1);
                    this.$router.replace({ name: 'Wagers', params: { book_id: this.$route.params.book_id } });
                });
            },
            addToSettlement(index) {
                (!this.selections.includes(index)) ? this.selections.push(index) : this.selections.splice(this.selections.indexOf(index), 1);
            },
            settleWager() {
                this.$store.dispatch('updateLoading', 1);
                settleWager(this.$route.params.wager_id, this.selections, null).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                });
            },
            settleOverUnder(e) {

                e.preventDefault();
                this.$store.dispatch('updateLoading', 1);

                settleWager(this.$route.params.wager_id, [], parseFloat(this.finalTotal)).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                });

            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #EditWager {
        padding: 0 0 50px;
    }

    .settle {
        padding: size(Medium) 0;
    }

    .settle .content,
    .settle .winning-lines {
        padding: size(Medium);
    }

    .settle .name, .settle .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .settle .name {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .settle .name p.footnote {
        font-weight: 700;
        margin-top: 2px;
    }

    .settle .row {
        padding-top: size(Medium);
    }

    .settle .count {
        color: color(Emperor, 0.25);
    }

    .settle .winning-lines {
        border-top: 1px dashed color(Emperor, 0.15);
    }

    .settle .winning-lines > h4 {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .delete, .lines .buttons {
        padding: size(Medium) size(Medium) + size(Large) 0;
    }

    .delete p.footnote {
        margin-top: size(Small);
    }

    .final-total {
        padding-top: size(Small);
    }

    .final-total h3 {
        margin-bottom: size(Small);
    }

    .final-total .Button {
        margin-top: size(Small);
    }

    .lines {
        padding-top: size(Medium);
    }

    .lines .line {

        padding: 12px size(Medium);
        background-color: color(White);

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid color(Emperor, 0.15);

    }

    .lines .line:first-of-type {
        border-top: 1px solid color(Emperor, 0.15);
    }

    .lines .line:active,
    .lines .line.active {
        background-color: color(Emperor);
        color: color(White);
    }

    h4.odds {
        color: color(OldGold);
    }

    h4.odds.push {
        color: color(Emperor, 0.25);
    }

    .lines .buttons .Button {
        margin-bottom: size(Small);
    }

    .success {
        margin-top: size(Medium);
    }

    .success p {
        margin-top: size(Small);
    }

    .success .Button {
        margin-top: size(Large);
    }

</style>
